<template>
  <div class="infoCard">
    <p>Forord</p>
    <p>
      Formålet med denne side er at hjælpe dig med at holde styr på
      <u>alle</u> dine bøger. De bøger du har, dem du har lånt ud, og de bøger
      ønsker dig.
    </p>
    <p>
      Forhåbentligt kan du også blive inspireret til at læse nogle bøger, du
      ikke vidste kunne være interessante. Og måske kan du og din bogsamling
      inspirere andere.
    </p>
    <p></p>
    <p>
      Du kan læse mere om hvordan det fungerer og visionen bag, under PROJEKTET.
    </p>
    <p>Rigtig god fornøjelse</p>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="css" scoped>
.infoCard {
  font-family: "Special Elite", "IBM Plex Mono", monospace;
  font-size: large;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: justify;
  text-overflow: clip;
  padding: 5vh;
  max-width: 25em;
}
</style>
