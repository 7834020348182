<template>
  <q-page class="flex flex-center q-pa-md">
    <div class="row items-start q-gutter-md">
      <q-card class="card left col">
        <info-card />
      </q-card>
    </div>
    <div class="row items-start q-gutter-md">
      <q-card class="card right col">
        <info-card />
      </q-card>
    </div>
  </q-page>
</template>

<style></style>

<script>
import InfoCard from "./general/infoCard.vue";
export default {
  name: "HelloWorld",
  components: { InfoCard },
};
</script>

<style lang="css" scoped>
.left {
  background: #fcfcfc;
  max-width: 25em;
}
.right {
  background: #ffe;
}
</style>
