<template>
  <img
    class="noCookie"
    alt="NO COOKIES"
    src="img/noCookies.png"
    width="110px"
    height="92"
  />
</template>

<script>
export default {
  setup() {},
  template:
    "<img class='noCookie' alt='NO COOKIES' src='img/noCookies.png' width='110px' height='92' />",
};
</script>

<style lang="css" scoped>
.noCookie {
  z-index: -20000;
  width: 110px;
  height: 92px;
  position: fixed;
  left: 0px;
  top: inherit;
}
</style>
